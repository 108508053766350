import React, { useState, useEffect } from 'react';
import Slider from 'react-slider';
import Header from "../components/Header";
import Footer from "../components/Footer";
const { bot } = require("../editable.json");

const CoinsPage = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [buyAmount, setBuyAmount] = useState(0);
  const [buyPrice, setBuyPrice] = useState(0);

  const [sellAmount, setSellAmount] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);

  const [configData, setConfigData] = useState(null);

  const parseInputValue = (value) => {
    if (value.trim() === '') return 0;
    const suffixMultiplier = {
      'm': 1000000,
      'b': 1000000000,
    };
    const regex = /(\d+(?:\.\d+)?)([mb]?)$/i;
    const match = value.match(regex);
  
    if (match) {
      const amount = parseFloat(match[1]);
      const suffix = match[2].toLowerCase();
      return amount * (suffixMultiplier[suffix] || 1);
    }
    return NaN;
  };
  

  useEffect(() => {
    setIsLoading(true);

    fetch(`https://listing.noemt.dev/bot/${bot}/data`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setIsLoading(false);

        const minBuy = data.other_config?.minBuy || 0;
        const minSell = data.other_config?.minSell || 0;

        const dynamicBuyPrice = data.other_config.coinBuyDynamic;
        const dynamicSellPrice = data.other_config.coinSellDynamic;
        const buyPrice = data.config.coinPriceBuy;
        const sellPrice = data.config.coinPriceSell;

        const configData = {
          coinBuyDynamic: dynamicBuyPrice,
          coinSellDynamic: dynamicSellPrice,
          coinPriceBuy: buyPrice,
          coinPriceSell: sellPrice,
          minBuy: minBuy,
          minSell: minSell,
        };

        setConfigData(configData);
        setBuyAmount(minBuy);
        setBuyPrice(calculateBuyPrice(minBuy, configData));
        setSellAmount(minSell);
        setSellPrice(calculateSellPrice(minSell, configData));
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const calculateBuyPrice = (amount, configData) => {
    const coinBuyDynamic = configData.coinBuyDynamic || [];
    let priceToPay = 0;
    const millions = amount / 1000000;
    let amountRemaining = amount;
    const coinPriceBuy = configData.coinPriceBuy;

    if (coinBuyDynamic.length === 0) {
      priceToPay = coinPriceBuy * millions;
    } else {
      const firstThreshold = coinBuyDynamic[0]["over"];
      for (let index = 0; index < coinBuyDynamic.length; index++) {
        const tier = coinBuyDynamic[index];
        const tierMulti = tier["price"];
        const tierThreshold = tier["over"];
        let millionsUnderFirstThreshold = 0;

        if (index === 0) {
          if (amount <= tierThreshold) {
            priceToPay = coinPriceBuy * millions;
            break;
          }
          millionsUnderFirstThreshold = tierThreshold / 1000000;
          priceToPay += (millionsUnderFirstThreshold * coinPriceBuy);
        }

        if (index === coinBuyDynamic.length - 1) {
          if (coinBuyDynamic.length !== 1) {
            amountRemaining -= ((millionsUnderFirstThreshold * 1000000) + firstThreshold);
            priceToPay += (amountRemaining / 1000000 * tierMulti);
            break;
          } else {
            amountRemaining -= (millionsUnderFirstThreshold * 1000000);
            priceToPay += (amountRemaining / 1000000 * tierMulti);
            break;
          }
        }

        const nextTierThreshold = coinBuyDynamic[index + 1]["over"];
        const diffInMillions = (nextTierThreshold - tierThreshold) / 1000000;

        if (amount > nextTierThreshold) {
          priceToPay += (diffInMillions * tierMulti);
          amountRemaining -= (diffInMillions * 1000000);
        } else {
          amountRemaining -= (millionsUnderFirstThreshold * 1000000);
          priceToPay += (amountRemaining / 1000000 * tierMulti);
          break;
        }
      }
    }

    return priceToPay;
  };

  const calculateSellPrice = (amount, configData) => {
    const coinSellDynamic = configData.coinSellDynamic || [];
    let priceToPay = 0;
    const millions = amount / 1000000;
    let amountRemaining = amount;
    const coinPriceSell = configData.coinPriceSell;

    if (coinSellDynamic.length === 0) {
      priceToPay = coinPriceSell * millions;
    } else {
      const firstThreshold = coinSellDynamic[0]["over"];
      for (let index = 0; index < coinSellDynamic.length; index++) {
        const tier = coinSellDynamic[index];
        const tierMulti = tier["price"];
        const tierThreshold = tier["over"];
        let millionsUnderFirstThreshold = 0;

        if (index === 0) {
          if (amount <= tierThreshold) {
            priceToPay = coinPriceSell * millions;
            break;
          }
          millionsUnderFirstThreshold = tierThreshold / 1000000;
          priceToPay += (millionsUnderFirstThreshold * coinPriceSell);
        }

        if (index === coinSellDynamic.length - 1) {
          if (coinSellDynamic.length !== 1) {
            amountRemaining -= ((millionsUnderFirstThreshold * 1000000) + firstThreshold);
            priceToPay += (amountRemaining / 1000000 * tierMulti);
            break;
          } else {
            amountRemaining -= (millionsUnderFirstThreshold * 1000000);
            priceToPay += (amountRemaining / 1000000 * tierMulti);
            break;
          }
        }

        const nextTierThreshold = coinSellDynamic[index + 1]["over"];
        const diffInMillions = (nextTierThreshold - tierThreshold) / 1000000;

        if (amount > nextTierThreshold) {
          priceToPay += (diffInMillions * tierMulti);
          amountRemaining -= (diffInMillions * 1000000);
        } else {
          amountRemaining -= (millionsUnderFirstThreshold * 1000000);
          priceToPay += (amountRemaining / 1000000 * tierMulti);
          break;
        }
      }
    }

    return priceToPay;
  };

  const handleBuySliderChange = (value) => {
    if (configData) {
      setBuyAmount(value);
      const calculatedPrice = calculateBuyPrice(value, configData);
      setBuyPrice(calculatedPrice.toFixed(2));
    }
  };

  const handleSellSliderChange = (value) => {
    if (configData) {
      setSellAmount(value);
      const calculatedPrice = calculateSellPrice(value, configData);
      setSellPrice(calculatedPrice.toFixed(2));
    }
  };

  const handleBuyInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseInputValue(value);
    if (!isNaN(parsedValue)) {
      handleBuySliderChange(parsedValue);
    }
  };

  const handleSellInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseInputValue(value);
    if (!isNaN(parsedValue)) {
      handleSellSliderChange(parsedValue);
    }
  };

  return (
    <div className="flex flex-col min-h-screen text-white">
      <Header />

      <div className="flex-grow">

        <h2 className="text-3xl font-bold text-center mb-8 mt-12">
          Buy/Sell Coins
        </h2>

        {isLoading ? (
          <p className="text-center">Loading...</p>
        ) : (
          data && (
            <div className="flex flex-col md:flex-row justify-center items-center space-x-0 md:space-x-4 space-y-4 md:space-y-0 mx-4">
              <div className="price-calculator w-full md:w-2/5 darker-discord-background text-white p-5 rounded-lg">
                <h2 className="text-xl font-bold mb-4">Buy Coins</h2>
                <Slider
                  min={data.other_config?.minBuy || 0}
                  max={20000000000}
                  value={buyAmount}
                  onChange={handleBuySliderChange}
                  step={1000000}
                  className="custom-slider"
                  thumbClassName="custom-thumb"
                  trackClassName="custom-track"
                />
                <div className="values flex justify-between mt-2 text-sm">
                  <span>{(data.other_config?.minBuy || 0) / 1000000} million</span>
                  <span>{buyAmount.toLocaleString()}</span>
                  <span>20 billion</span>
                </div>
                <div className="price mt-2 text-lg font-semibold">
                  Price: ${buyPrice?.toLocaleString()}
                </div>
                <input
                  type="text"
                  className="w-full mt-4 p-2 text-black rounded-xl"
                  value={buyAmount === 0 ? '' : buyAmount}
                  onChange={handleBuyInputChange}
                />
              </div>

              <div className="price-calculator w-full md:w-2/5 darker-discord-background text-white p-5 rounded-lg">
                <h2 className="text-xl font-bold mb-4">Sell Coins</h2>
                <Slider
                  min={data.other_config?.minSell || 0}
                  max={20000000000}
                  value={sellAmount}
                  onChange={handleSellSliderChange}
                  step={1000000}
                  className="custom-slider"
                  thumbClassName="custom-thumb"
                  trackClassName="custom-track"
                />
                <div className="values flex justify-between mt-2 text-sm">
                  <span>{(data.other_config?.minSell || 0) / 1000000} million</span>
                  <span>{sellAmount.toLocaleString()}</span>
                  <span>20 billion</span>
                </div>
                <div className="price mt-2 text-lg font-semibold">
                  Price: ${sellPrice?.toLocaleString()}
                </div>
                <input
                  type="text"
                  className="w-full mt-4 p-2 text-black rounded-xl"
                  value={sellAmount === 0 ? '' : sellAmount}
                  onChange={handleSellInputChange}
                />
              </div>
            </div>
          )
        )}
        </div>
      <Footer />
    </div>
  );
};

export default CoinsPage;
