import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";

const Confirm = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [userInput, setUserInput] = useState("");
  const [comparisonResult, setComparisonResult] = useState(null);
  const [resultStyle, setResultStyle] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://listing.noemt.dev/nom");

        let data = response.data;
        let vouches = data.data.vouches;
        let processed_vouches = [];

        vouches.forEach((vouch) => {
          if (vouch.avatar == null)
            vouch.avatar = "https://cdn.discordapp.com/embed/avatars/5.png";
          processed_vouches.push(vouch);
        });
        data.data.vouches = processed_vouches;

        setData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (userInput === "") {
      setComparisonResult("Enter a Discord ID to compare!");
      setResultStyle({});
    } else if (data && data.data.nom && data.data.nom[0]) {
      if (userInput === data.data.nom[0]) {
        setComparisonResult("This is my Discord Account!");
        setResultStyle({ color: "#90ee90" });
      } else {
        setComparisonResult(
          "This is not my Discord Account! This person is impersonating me!"
        );
        setResultStyle({ color: "red" });
      }
    }
  }, [userInput, data]);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  return (
    <div className="flex flex-col min-h-screen text-white bg-gray-800">
      <nav className="bg-gray-900 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <img
              src="/assets/icon.webp"
              alt="Nom Icon"
              className="w-10 h-10 mr-4 rounded-full"
            />
            <span className="text-xl font-semibold">Nom</span>
          </div>
          <div className="flex space-x-4">
            <a
              href="https://bots.noemt.dev/"
              className="text-white hover:bg-gray-700 px-3 py-2 rounded"
            >
              Listing Bots
            </a>
            <a
              href="https://list.noemt.dev/"
              className="text-white hover:bg-gray-700 px-3 py-2 rounded"
            >
              Scammer List
            </a>
            <a
              href="https://noemt.dev/"
              className="text-white hover:bg-gray-700 px-3 py-2 rounded"
            >
              Shop Page
            </a>
            <a
                href="https://discord.gg/noms"
                className="text-white hover:bg-gray-700 px-3 py-2 rounded"
            >
                Discord
            </a>
          </div>
        </div>
      </nav>
      <main className="flex-grow p-8">
        <section className="flex justify-center mb-14">
          <div className="w-full max-w-2xl text-left flex items-center">
            <img
              src="/assets/icon.webp"
              alt="Nom Icon"
              className="w-16 h-16 md:w-24 md:h-24 mr-4"
            />{" "}
            <div>
              <motion.h1
                className="text-5xl md:text-7xl font-bold"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, ease: "easeOut" }}
              >
                nom
              </motion.h1>
              <p className="text-lg md:text-xl mb-8">
                a developer from Germany
              </p>
            </div>
          </div>
        </section>
        {loading ? (
          <p className="text-center text-3xl">Loading Page Content...</p>
        ) : error ? (
          <p className="text-center">Loading Page Content...</p>
        ) : (
          <div>
            <h1 className="text-4xl text-center mb-2">
              Check if an Account is me
            </h1>
            <div className="flex justify-center">
              <input
                type="text"
                value={userInput}
                onChange={handleInputChange}
                className="p-2 rounded bg-gray-700 text-white"
              />
            </div>
            {comparisonResult && (
              <p className="mt-2 text-center mb-4" style={resultStyle}>
                {comparisonResult}
              </p>
            )}
            {data && data.data.nom && data.data.nom[0] && (
              <div className="flex justify-center items-center mt-8">
                <div className="text-center mb-8 border-solid border-2 border-gray-700 rounded-lg shadow-lg p-6 bg-gray-800 w-full max-w-md">
                  <h2 className="text-3xl font-semibold mb-4">
                    My only Discord Account
                  </h2>
                  <div className="flex justify-center items-center mt-4">
                    <img
                      src="/assets/icon.webp"
                      alt="Profile"
                      className="w-16 h-16 rounded-full"
                    />
                    <p className="ml-4 text-xl">{data.data.nom[0]}</p>
                  </div>
                </div>
              </div>
            )}
            <h1 className="text-4xl text-center">
              My Vouches (${data.data.amount}, {data.data.vouches.length}{" "}
              entries)
              <br />
              <span className="text-sm">
                (since I started tracking them, if I vouched for myself it's
                either for testing or for tracking purposes)
              </span>
            </h1>

            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 mx-8 my-8">
              {data.data.vouches.map((vouch) => (
                <div
                  key={vouch.id}
                  className="bg-gray-900 rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow duration-300 relative"
                >
                  <div className="flex items-center">
                    <img
                      src={vouch.avatar}
                      alt={`${vouch.username} avatar`}
                      className="w-10 h-10 rounded-full mb-4 object-cover border-solid border-white border-2"
                    />
                    <h3 className="text-xl font-semibold text-left ml-2 mb-4">
                      <a
                        className="hover:underline"
                        href={`https://discord.com/users/${vouch.id}`}
                      >
                        {vouch.username}
                      </a>
                    </h3>
                  </div>
                  <p>{vouch.message}</p>
                </div>
              ))}
            </div>
            <h1 className="text-4xl text-center">
              My completed middleman transactions ({data.data.middleman.length}{" "}
              entries)
            </h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full gap-8 mx-8 my-8">
              {data.data.middleman.map((log) => (
                <div
                  key={log.id}
                  className="bg-gray-900 text-white rounded-lg p-6 shadow-lg hover:shadow-2xl transition-shadow duration-300 relative mt-3 mx-8"
                >
                  <div className="border-b border-gray-700 pb-4 mb-4">
                    <h2 className="text-2xl font-semibold">
                      Transaction Details
                    </h2>
                  </div>
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="font-medium text-gray-400">ID:</span>
                      <span className="font-light">{log.id}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="font-medium text-gray-400">
                        Buyer ID:
                      </span>
                      <span className="font-light">{log.buyer_id}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="font-medium text-gray-400">
                        Seller ID:
                      </span>
                      <span className="font-light">{log.seller_id}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="font-medium text-gray-400">Amount:</span>
                      <span className="font-light">{log.amount}</span>
                    </div>

                    <div className="flex justify-between">
                      <span className="font-medium text-gray-400">Date:</span>
                      <span className="font-light">{log.date}</span>
                    </div>

                    <div className="flex justify-between">
                      <span className="font-medium text-gray-400">
                        Deal Info:
                      </span>
                      <span className="font-light">{log.info}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Confirm;
