import React, { useState, useEffect } from "react";
import Slider from "react-slider";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Middleman = () => {
  const [data, setData] = useState(null);
  const [logs, setLogs] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const [amount, setAmount] = useState(0);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    setIsLoading(true);

    fetch(`https://backup.noemt.dev/mm`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLogs(data.log.reverse());
        setIsLoading(false);

        setAmount(0);
        setPrice(calculatePrice(0, data));
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const calculatePrice = (amount, data) => {
    let basePrice = data.fees.min;
    if (amount * data.fees.rate > basePrice) return amount * data.fees.rate;
    return basePrice;
  };

  const handleSliderChange = (value) => {
    setAmount(value);
    if (data) {
      const calculatedPrice = calculatePrice(value, data);
      setPrice(calculatedPrice.toFixed(2));
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue)) {
      handleSliderChange(parsedValue);
    }
  };

  return (
    <div className="flex flex-col min-h-screen text-white">
      <Header />

      <div className="flex-grow">
        <h2 className="text-3xl font-bold text-center mb-8 mt-12">
          Request A Middleman
        </h2>

        {isLoading ? (
          <p className="text-center">Loading...</p>
        ) : (
          data && (
            <div className="flex flex-col justify-center items-center space-y-4 mx-4">
              <div className="price-calculator w-full md:w-2/5 darker-discord-background text-white p-5 rounded-lg">
                <h2 className="text-2xl font-bold mb-4">
                  Middleman Fee Calculator
                </h2>
                <Slider
                  min={0}
                  max={10000}
                  value={amount}
                  onChange={handleSliderChange}
                  step={1}
                  className="custom-slider"
                  thumbClassName="custom-thumb"
                  trackClassName="custom-track"
                />
                <div className="values flex justify-between mt-2 text-sm">
                  <span>0</span>
                  <span>{amount.toLocaleString()}</span>
                  <span>10,000</span>
                </div>
                <div className="price mt-2 text-lg font-semibold">
                  Fee: ${price?.toLocaleString()}
                </div>
                <input
                  type="text"
                  className="w-full mt-4 p-2 text-black rounded-xl"
                  value={amount === 0 ? "" : amount}
                  onChange={handleInputChange}
                />
              </div>
              <h2 className="text-3xl font-bold text-center mb-8 mt-12">
                My completed Deals
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full gap-8">
                {logs.map((log) => (
                  <div
                    key={log.id}
                    className="darker-discord-background text-white rounded-lg p-6 shadow-lg hover:shadow-2xl transition-shadow duration-300 relative mt-3 mx-8"
                  >
                    <div className="border-b border-gray-700 pb-4 mb-4">
                      <h2 className="text-2xl font-semibold">
                        Transaction Details
                      </h2>
                    </div>
                    <div className="space-y-3">
                      <div className="flex justify-between">
                        <span className="font-medium text-gray-400">ID:</span>
                        <span className="font-light">{log.id}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="font-medium text-gray-400">
                          Buyer ID:
                        </span>
                        <span className="font-light">{log.buyer_id}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="font-medium text-gray-400">
                          Seller ID:
                        </span>
                        <span className="font-light">{log.seller_id}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="font-medium text-gray-400">
                          Amount:
                        </span>
                        <span className="font-light">{log.amount}</span>
                      </div>

                      <div className="flex justify-between">
                        <span className="font-medium text-gray-400">Date:</span>
                        <span className="font-light">{log.date}</span>
                      </div>

                      <div className="flex justify-between">
                        <span className="font-medium text-gray-400">
                          Deal Info:
                        </span>
                        <span className="font-light">{log.info}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Middleman;
