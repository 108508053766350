import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFoundPage from "./pages/404";
import Index from "./pages/index";
import Terms from "./pages/terms";
import Vouches from "./pages/vouches";
import Accounts from "./pages/accounts";
import Coins from "./pages/coins";
import PrivacyPolicy from "./pages/privacy";
import Middleman from "./pages/middleman";
import Confirm from "./pages/confirm";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/vouches" element={<Vouches />} />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/coins" element={<Coins />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/middleman" element={<Middleman />} />
        <Route path="/confirm" element={<Confirm />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
